<template>
  <div class="my">
    <el-container>
      <el-aside width="250px">
        <el-menu
          :default-active="currPath"
          @select="handleMenuSelect"
          background-color="#faf8f5"
          active-text-color="#E78"
          :default-openeds="openeds"
        >
          <el-submenu v-for="(item, index) in menus" :index="String(index)" :key="index">
            <template slot="title">
              <i :class="item.icon"></i>
              <span>{{item.title}}</span>
            </template>
            <el-menu-item v-for="menu in item.children" :key="menu.path" :index="menu.path">
              <span slot="title">{{menu.title}}</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-main>
        <div class="my-content">
          <router-view></router-view>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import request from '@/utils/request';
import Cookies from 'js-cookie';

export default {
  data() {
    return {
      currPath: '',
      openeds: ['0', '1'],
      menus: [
        {
          title: 'マイページ',
          icon: 'el-icon-menu',
          children: [
            {
              path: '/mypage/informations',
              title: 'インフォメーション',
            },
            {
              path: '/mypage/courses',
              title: '講座受講一覧',
            },
            // {
            //   path: '/mypage/feedbacks',
            //   title: 'アンケート一覧',
            // },
            {
              path: '/mypage/qas',
              title: 'お問い合わせ',
            },
            // {
            //   path: '/mypage/certifications',
            //   title: '講座受講終了証一覧',
            // },
          ],
        },
        {
          title: '設定',
          icon: 'el-icon-setting',
          children: [
            {
              path: '/mypage/setprofile',
              title: '本人情報',
            },
            {
              path: '/mypage/setpassword',
              title: 'パスワード変更',
            },
            // {
            //   path: '/mypage/setmail',
            //   title: 'メール変更',
            // },
            {
              path: 'logout',
              title: 'ログアウト',
            },
          ],
        },
      ],
    };
  },
  created() {
    this.currPath = this.$route.path;
  },
  watch: {
    $route(to) {
      this.currPath = to.path;
    },
  },
  methods: {
    handleMenuSelect(path) {
      if (path === 'logout') {
        request('logout').finally(() => {
          Cookies.remove('token');

          this.$router.push({
            path: '/login',
          }).catch(() => {});
        });
        return;
      }
      this.$router.push({
        path,
      }).catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.my {
  margin: 30px 0;

  .el-menu {
    border: none;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid #ccc;

    .el-menu-item:hover {
      background-color: #eee!important;
    }
  }
  .el-main {
    // background-color: #faf8f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-left: 20px;
    padding: 0;
  }
}
@media (max-width: 767px) {
  .my {
    margin: 10px 0;

    .el-aside {
      display: none;
    }
    .el-main {
      margin-left: 0;
      border: none;
    }
  }
}
</style>
